<template>
  <v-autocomplete
    v-model="relations"
    v-bind="$attrs"
    chips
    outlined
    return-object
    hide-details
  />
</template>

<script>

import modelMixin from '@/mixins/modelMixin'
import { difference } from 'lodash'

export default {
  name: 'ManyToManySelector',
  mixins: [modelMixin],
  props: {
    relation: {
      type: String,
      required: true
    },
    companyId: {
      type: String,
      required: true
    },
    establishmentId: {
      type: String,
      required: true
    }
  },
  computed: {
    relations: {
      get () {
        return this.value.map(relation => relation[this.relation])
      },
      set (values) {
        const addedObjects = difference(values, this.relations)
        const removedObjects = difference(this.relations, values)

        if (addedObjects.length > 0) {
          this.fields.push({
            companyId: this.companyId,
            establishmentId: this.establishmentId,
            [this.relation]: addedObjects[0],
            [`${this.relation}Id`]: addedObjects[0].id,
            rank: this.fields.length
          })
        }
        if (removedObjects.length > 0) {
          this.fields = this.fields.filter(relation => !removedObjects.map(o => o.id).includes(relation[this.relation].id))
        }

        this.fields.forEach((instance, index) => {
          instance.rank = index
        })

        this.$emit('input', this.fields)
      }
    }
  }
}
</script>
